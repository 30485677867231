
.page{
    height: fit-content;
    width: 100%;
    max-width: 1920px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    overflow: hidden;
    background-color: rgba(255, 224, 163, 0.75);
}
