
.intro_card_shrimp {
    position: absolute;
    height: auto;
}

.intro_shrimp_container{
    width: 100%;
    height: 100%;
}
