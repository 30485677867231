
.page_item {
    position: relative;
    padding: 0.25rem 0 1rem 0;
    background-color: coral;
    color: white;
    box-shadow: 0 2px 10px 2px rgba(70, 70, 70, 0.80);
    border: 1px solid rgba(88, 30, 8, 0.32);
    border-radius: 0.5rem;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem 0 1rem 0;
    transition: transform 0.5s; /* Smooth transition */
}
.anchor{
    position: absolute;
    height: 10px;
    top: -100px;
}
.section_header {
    font-size: 2rem;
    text-align: center;
    font-weight: bolder;
    padding: 1rem;
}

.sub_content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    overflow: hidden;
    padding: 0.25rem;
}

.section_image_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.section_image{
    width: 90%;
    height: auto;
}
