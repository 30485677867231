
.archive_modal_background {
    background-color: rgba(185, 185, 185, 0.3);
    backdrop-filter: blur(10px);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.archive_container {
    width: 100%;
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
}

.archive_container::-webkit-scrollbar{
    display: none;
}

.archive_close_buttons{
    position: fixed;
    width: 40px;
    height: 40px;
    z-index: 9999;
    top: 0;
    right: 0;
    margin: 1rem 1rem 0 0;
    background-color: #ffc293;
    border: 2px solid black;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.archive_close_buttons {
    cursor: pointer;
}

.archive_close_buttons:active{
    background-color: #fff2e9;
}
@media (max-width: 768px) {
    .archive_close_buttons{
        width: 25px;
        height: 25px;
        border: 1px solid black;
        border-radius: 0.5rem;
        margin: 0.5rem 0.5rem 0 0;
    }
}
