.debug {
  outline: 1px solid red;
}

.wrapper {
  position: relative;
}

.navbar {
  z-index: 100000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 0;
  min-height: 3rem;
  max-height: 3rem;
  background-color: #3F6ED1;
  color: white;
  box-shadow: 0 5px 0 #224694;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: end;
}

.logo {
  cursor: pointer;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.content {
  height: 2rem;
  display: flex;
    justify-content: center;
  align-items: center;
    gap: 2rem;
    margin: 0 2rem 0 0;
  transition: 0.3s;
  background-color: #3F6ED1;
}

.content_item {
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_item > * {
  cursor: pointer;
  flex-grow: 0;
  padding: 0.5rem;
  text-align: center;
  transition: 0.3s;
  box-shadow: -3px 5px 0 0 #c62700;
  background: #f81753e0;
  border-radius: 0.5rem;
    width: 125px;
}

.content_item > *:hover {
    flex-grow:0.15;
}

.menu {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
  height: fit-content;
  border-radius: 0.5rem;
  background-color: #ff8080;

}

.menu:hover {
  background-color: #bdbdbd;
}

/* Content becomes vertical dropdown content on mobile */
@media (max-width: 768px) {
  .content {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    width: 100%;
    gap: 3rem;
    padding: 0;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
  }

  .content_item {
    flex-grow: 0;
    height: auto;
    width: 75%;
  }

  .open {
    height: 100vh;
  }
}

.spacer {
  height: 4rem;
}

.sticky {
  position: fixed;
}
