
.card_wrapper {
    position: relative;
    aspect-ratio: 14/16;
    max-height: 400px;
    padding: 0.5rem;
    overflow: hidden;
    box-shadow: -2px 5px 5px 2px rgba(0, 0, 0, 0.13);
    background: #f81753;
    color: white;
    border-radius: 0.5rem;
    transition: 0.3s;
}

.card_wrapper:hover {
    box-shadow: -6px 10px 10px 2px rgba(0, 0, 0, 0.13);
    translate: 3px -5px;
    cursor: pointer;
}

.card_title {
    position: absolute;
    top: -0.1rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 3;
    background: #f81753;
    padding: 0.5rem;
    border-radius: 0 0 0.75rem 0.75rem;
    font-weight: bold;
}

.card_description {
    position: absolute;
    width: calc(100% - 1rem);
    max-height: 70%;
    overflow: hidden;
    bottom: 0;
    height: fit-content;
    z-index: 3;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 127, 80, 0.66);
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.card_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
}

@media (max-width: 758px) {
    .card_wrapper {
        width: 80%;
    }
}
