.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #224694;
    color: white;
}

.footer_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 8vh 0 4vh 0;
    flex-grow: 1;
}

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}

.contact_item {
    text-decoration: none;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: #224694;
    color: white;
    transition: 0.3s;
}

.contact_item:hover {
    box-shadow: -3px 5px 0 0 #3F6ED1;
    transform: translate(3px, -5px);
}

.partners {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}
