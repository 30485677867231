.switch{
    display: flex;
    align-items: center;
}

.switch_checkbox{
    height: 0;
    width: 0;
    visibility: hidden;
}

.switch_label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 55px;
    height: 28px;
    background: grey;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0.37);
    position: relative;
    transition: background-color 0.2s;
}

.switch_label .switch_button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch_checkbox:checked + .switch_label {
    background: #4cd137;
}

.switch_checkbox:checked + .switch_label .switch_button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.switch_label:active .switch_button {
    width: 30px;
}
