@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');
.lineup_wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 70vw;
}
.time__date{
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
}

.lineup_text_shrimp {
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 0.25rem;
    margin:5px 0 5px 0;
    display: block;
    font-size: 4em;
    text-transform: uppercase;
    transition-property: color;
    background-image: url("../../resources/shrimp-cherry.gif");
    background-position: center;
    background-size: contain;
    background-repeat: round;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: transform 200ms ease-in-out;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000000;

}

.lineup_text_no_shrimp {
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 0.25rem;
    margin:5px 0 5px 0;
    display: block;
    font-size: 4em;
    text-transform: uppercase;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000000;
}
@media (max-width: 758px) {
    .lineup_text_no_shrimp {
        font-size: 2rem;
    }
    .lineup_text_shrimp{
        font-size: 2rem;
    }
}

.artist_date_wrapper{
    display: flex;
    flex-direction: row;
}

.artist{
    font-weight: bolder;
    font-size: 1rem;
}
