
.intro_card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4rem 0 4rem 0;
    overflow: hidden;
    background-color: coral;
    box-shadow: 0 2px 10px 2px rgba(70, 70, 70, 0.80);
}

.intro_card > h3 {
    text-align: center;
    margin: 1rem 2rem 0 2rem;
    z-index: 99;
    color: white;
    text-shadow: 0 0 4px black;
}

.intro_card_img {
    max-width:80%;
    height: auto;
}


.page_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.noScroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}
