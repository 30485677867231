
.page_header_wrapper{
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: coral;
    box-shadow: 0 2px 10px 2px rgba(70, 70, 70, 0.69);
    color: white;
    margin: 0;
    position: sticky;
    z-index: 999;
}

.page_header {
    font-size: 3rem;
    text-align: center;
    font-weight: bolder;
    padding: 0;
    margin: 0;
}

.item{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}
